import React from 'react';
import Media from '../services/Media';
import './page.css';
import Helper from '../services/Helper';
import { Link } from 'react-router-dom';
import LaddaNed from './Download';

class Page extends React.Component {
    _isMounted = false;

    constructor() {
        super();
        this.state = {
            page: {
                media: {},
                title: {},
                content: {},
            },
            posts: [],
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;      
        const { slug } = this.props.match.params
        this.updatePage(slug);
    }

    componentDidUpdate(prevProps) {
        const { slug } = this.props.match.params
        const oldslug = prevProps.match.params.slug
        if (oldslug !== slug) {
            this.updatePage(slug);
        }
    }

    updatePage(slug) {
        // use this while loading...
        this.handlePage("", {
            media: null,
            title: 'Laddar',
            content: 'Sidan laddar, vänligen vänta',
        });
        // fetch content from server
        fetch(Helper.fetchUrl() + `/wp-json/wp/v2/pages?slug=${slug}`)
            .then(response => response.json())
            .then(page => { this.handlePage(slug, page[0]) })
            .catch((err) => { console.log(err) })
        // if it is the posts page then fetch posts from server
        if (slug === "nyheter") {
            fetch(Helper.fetchUrl() + `/wp-json/wp/v2/posts`)
                .then(response => response.json())
                .then(response => { this.managePosts(response) })
                .catch((err) => { console.log(err) })
        }
        else {
            if (this._isMounted) {         
                this.setState({ posts: [] })
            }
        }
    }

    handleClickForFaq() {
        var div = this;
        var children = div.children;
        var firstchild = children[0];
        var child = firstchild.children[0];
        var secondChild = firstchild.children[1]
        if (child.children[1].style.display === "inline") {
            (child.children[1].style.display = "none");
            (secondChild.children[0].classList.remove('fa-angle-up'));
            (secondChild.children[0].classList.add('fa-angle-down'));
        } else {
            (child.children[1].style.display = "inline");
            (secondChild.children[0].classList.remove('fa-angle-down'));
            (secondChild.children[0].classList.add('fa-angle-up'));
        }
    }

    handlePage(slug, page) {
        var that = this;
        if (page.featured_media) {
            Media.fetchMedia(page.featured_media).then((image) => {
                page.media = image;
                document.title = page.title.rendered + ' | K for Contact';
                if (this._isMounted) {         
                    that.setState({ page }, () => {
                        if (slug === "faq") {
                            var that = this;
                            var classname = document.getElementsByClassName("que");
                            Array.prototype.forEach.call(classname, function (element) {
                                element.addEventListener('click', that.handleClickForFaq);
                            });
                        }
                    })
                }
            })
        } else {
            document.title = page.title.rendered + ' | K for Contact';
            if (this._isMounted) {         
                that.setState({ page }, () => {
                    if (slug === "faq") {
                        var that = this;
                        var classname = document.getElementsByClassName("que");
                        Array.prototype.forEach.call(classname, function (element) {
                            element.addEventListener('click', that.handleClickForFaq);
                        });
                    }
                })
            }
        }
    }

    managePosts(posts) {
        var promises = [];
        for (var i = 0; i < posts.length; i++) {
            promises.push(Media.fetchMedia(posts[i].featured_media));
        }
        var that = this;
        Promise.all(promises).then(function (values) {
            for (var i = 0; i < posts.length; i++) {
                posts[i].media = values[i];
            }
            if (that._isMounted) {         
                that.setState({
                    posts: posts
                })
            }
        });
    }

    renderImage() {
        if (this.state.page.media && this.state.page.media.source_url) {
            return (
                <img src={this.state.page.media.source_url}
                    alt={"feturedImage"} />
            )
        } else {
            return null;
        }
    }

    renderPage() {
        if (this.state.posts.length > 0) {
            // this is a posts page
            return (
                <div className="content" >
                    <h1>{this.state.page.title.rendered}</h1>
                    {this.renderPostNyheter()}
                </div>
            );
        }
        else {
            // this is a normal page
            return (
                <div className="content" >
                    <div className="page" >
                        <h1>{this.state.page.title.rendered}</h1>
                        <div dangerouslySetInnerHTML={{ __html: this.state.page.content.rendered }} />
                    </div>
                </div>
            );
        }
    }
    renderPostNyheter() {
        var listItems = this.state.posts.map((post, index) =>
            <div key={index} className="container set">
                <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="card-image">
                            <Link to={"/post/" + post.slug} className="">
                                <img width="100%" height="240" src={post.media.source_url}
                                    alt={"nyheter"} />
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-12">
                        <Link to={"/post/" + post.slug}>
                            <h2>{post.title.rendered}</h2>
                        </Link>
                        <em>{new Date(post.date).toLocaleString()}</em>
                        <div dangerouslySetInnerHTML={{ __html: post.excerpt ? post.excerpt.rendered : null }} />
                    </div>
                </div>
            </div>
        );
        return listItems;
    }

    render() {
        const { slug } = this.props.match.params
        if (slug === 'ladda-ned') {
            return <LaddaNed page={this.state.page} />
        }
        else {
            return (
                <div className="Container">
                    <div className="img-fluid text-center background" id="featuredimage">
                        <div className=" main-image text-center" alt="Responsive image">
                            {this.renderImage()}
                        </div>
                    </div>
                    <div className="container">
                        {this.renderPage()}
                    </div>
                </div>
            )
        }
    }
}
export default Page;
import React from 'react';
import './Home.css';
import Categorie from './K-Categories';
import { connect } from 'react-redux';

class Home extends React.Component {

    render() {
        document.title = 'K for Contact';
        return (
            <div>
                {/* First Container */}
                <div id="featuredimage" className="container-fluid img-fluid text-center main-img">
                    <img src={require('../img/k_logo_trans_white_large.png')} className="img-responsive margin" alt="Bird" width="100%" />
                </div>
                <div className="container">
                    {/*Second Container */}
                    <div className="container-fluid bg-2 main-content text-center">
                        <Categorie categorieSlug="primary" />
                    </div>
                    {/*Third Container (Grid)*/}
                    <div className="container-fluid text bg-3 text-center">
                        <div className="row">
                            <Categorie categorieSlug="usps" />
                        </div>
                    </div>
                    <div className="container-fluid bg-2 text-center function">
                        <Categorie categorieSlug="secondary" />
                    </div>

                    {/*Third Container (Grid)*/}
                    <div className="container-fluid text bg-3 text-center">
                        <div className="row">
                            <Categorie categorieSlug="features" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        translation: state.translation
    };
}

export default connect(mapStateToProps)(Home);

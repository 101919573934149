import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import Address from './Address';
import { connect } from 'react-redux';
import FooterMenu from './Footer-menu';

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            footer: [],
        }
    }

    render() {
        return (
            <div>
                {/*Footer*/}
                <footer className="page-footer font-small teal pt-4">
                    {/*Footer Text*/}
                    <div className="container footer-group text-center text-md-left">
                        {/*Grid row*/}
                        <div className="row">
                            {/*Grid column */}
                            <div className="col-md-4 mt-md-0 mt-3">
                                {/*Content*/}
                                <h3 className="headline">
                                    <FontAwesomeIcon className="space" icon={faMapMarkerAlt} />
                                    Adress
                        </h3>
                                <ul>
                                    <Address />
                                </ul>
                            </div>
                            <div className="col-md-4 mt-md-0 mt-3">
                                <h3 className="headline">
                                    <FontAwesomeIcon className="space" icon={faMobileAlt} />
                                    Telefon / e-post
                        </h3>
                                <ul>
                                    <li><a className="footer-link" href="tel:+46706788773">+46 (0)70 - 678 87 73</a></li>
                                    <li><a className="footer-link" href="tel:info@kforcontact.com">info@kforcontact.com</a></li>
                                </ul>
                            </div>
                            <div className="col-md-4 mt-md-0 mt-3">
                                <h3 className="headline">
                                    <FontAwesomeIcon className="space" icon={faQrcode} />
                                    {this.props.translation.text["SHARE_CONTACT_PART3_HEADING"]}
                                </h3>
                                <ul>
                                    <li>
                                        <img src={require('../img/qr-code.gif')} className="img-responsive margin" alt="Bird" width="40%" />
                                    </li>
                                </ul>
                            </div>
                            {/* Grid column */}
                        </div>
                        {/*Grid row*/}
                    </div>
                    {/*Footer Text*/}
                </footer>
                {/*Footer*/}
                {/*Footer 2*/}
                <footer className="share-footer font-small teal p-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center ">
                                <FooterMenu />
                            </div>
                        </div>
                    </div>
                </footer>
                {/*Footer 2*/}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        translation: state.translation
    };
}

export default connect(mapStateToProps)(Footer);
import React from 'react';
import './Share.css';
import Categorie from './K-Categories';
import QrCode from './Qr-code';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import '../../node_modules/video-react/dist/video-react.css'; // import css
import { connect } from 'react-redux';
import VideoComponent from './VideoComponent';
import vCardsJS from 'vcards-js';
import { saveAs } from 'file-saver';


class Share extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.myRef = React.createRef()   // Create a ref object 
        this.state = {
            loading: true,
            share: null,
            error: null,
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        const { id } = this.props.match.params
        fetch(`https://api.kforcontact.com/v1/share/${id}`)
            .then(response => response.json())
            .then(share => {
                if (this._isMounted) {
                    this.setState({
                        loading: false,
                        share: share,
                    });
                }
            })
            .catch((error) => {
                if (this._isMounted) {
                    this.setState({
                        loading: false,
                        share: null,
                        error: error,
                    });
                }
            })
    }

    handleClick(e) {
        e.preventDefault()
    }

    _saveVCard(profile) {
        var vCard = vCardsJS();

        //set properties
        vCard.firstName = profile.givenname;
        vCard.lastName = profile.surname;
        vCard.organization = profile.company;
        vCard.photo.attachFromUrl('https://api.kforcontact.com/v1/image/' + this.state.share.profile.image_guid, 'JPEG');
        vCard.workPhone = profile.phone;
        vCard.email = profile.email;
        vCard.nickname = profile.nickname;

        const blob = new Blob([vCard.getFormattedString()], { type: "text/vcard;charset=utf-8" });
        saveAs(blob, profile.nickname + '.vcf');
    }

    _renderProfileDataTitle(type) {
        switch (type) {
            case "phone":
                return (<h6 className="mb-0">Telefon</h6>);
            case "email":
                return (<h6 className="mb-0 ">Epost</h6>);
            case "status_note":
                return (<h6 className="mb-0">Notering</h6>);
            case "out_of_office":
                return (<h6 className="mb-0">Frånvaromeddelande</h6>);
            case "website":
                return (<h6 className="mb-0">Hemsida</h6>);
            case "facebook":
                return (<h6 className="mb-0">Facebook</h6>);
            case "youtube":
                return (<h6 className="mb-0">YouTube</h6>);
            case "whatsapp":
                return (<h6 className="mb-0">WhatsApp</h6>);
            case "fbmessenger":
                return (<h6 className="mb-0">Messenger</h6>);
            case "wechat":
                return (<h6 className="mb-0">WeChat</h6>);
            case "instagram":
                return (<h6 className="mb-0">INSTAGRAM</h6>);
            case "qq":
                return (<h6 className="mb-0">QQ</h6>);
            case "qzone":
                return (<h6 className="mb-0">QZONE</h6>);
            case "tiktok":
                return (<h6 className="mb-0">TIKTOK</h6>);
            case "weibo":
                return (<h6 className="mb-0">WEIBO</h6>);
            case "reddit":
                return (<h6 className="mb-0">Reddit</h6>);
            case "twitter":
                return (<h6 className="mb-0">Twitter</h6>);
            case "douban":
                return (<h6 className="mb-0">Douban</h6>);
            case "linkedin":
                return (<h6 className="mb-0">LinkedIn</h6>);
            case "baidutieba":
                return (<h6 className="mb-0">Baidu Tieba</h6>);
            case "skype":
                return (<h6 className="mb-0">Skype</h6>);
            case "snapchat":
                return (<h6 className="mb-0">Snapchat</h6>);
            case "viber":
                return (<h6 className="mb-0">Viber</h6>);
            case "pintrest":
                return (<h6 className="mb-0">Pintrest</h6>);
            case "line":
                return (<h6 className="mb-0">Line</h6>);
            case "github":
                return (<h6 className="mb-0">GitHub</h6>);
            case "timezone":
                return (<h6 className="mb-0">Time zone</h6>);
            default:
                return (<h6 className="mb-0">{type}</h6>);
        }
    }

    _renderProfileDataValue(profiledata) {
        switch (profiledata.type) {
            case "phone":
                return (<a href={"tel:" + profiledata.value}>{profiledata.value}</a>);
            case "email":
                return (<a href={"mailto:" + profiledata.value}>{profiledata.value}</a>);
            case "facebook":
                    return (<a href={profiledata.value}>{profiledata.value}</a>);
            case "website":
                    return (<a href={profiledata.value}>{profiledata.value}</a>);
            default:
                return (profiledata.value);
        }
    }

    _renderProfileData(profile) {
        var listItems = profile.profiledata.map((profiledata, index) => {
            if (!profiledata.value) {
                return (null)
            } else {
                return (<div key={index}>
                    <hr />
                    <div className="row">
                        <div className="col-sm-3 align-left">
                            {this._renderProfileDataTitle(profiledata.type)}
                        </div>
                        <div className="col-sm-9 text-secondary align-left">
                            {this._renderProfileDataValue(profiledata)}
                        </div>
                    </div>
                </div>)
            }
        }
        );
        return listItems;
    }

    renderShare() {
        if (this.state.share && this.state.share.profile) {
            if (this.state.share.public) {
                return (
                    <div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 mb-3">
                                    <div className="card borderstyle">
                                        <div className="card-body">
                                            <div className="d-flex flex-column align-items-center text-center">
                                                <img src={'https://api.kforcontact.com/v1/image/' + this.state.share.profile.image_guid} alt="Admin" class="rounded-circle img-fluidrounded" width="150" />
                                                <div className="mt-3">
                                                    <h4>{(this.state.share.profile.givenname ? this.state.share.profile.givenname : "") + " " + (this.state.share.profile.surname ? this.state.share.profile.surname : "")}</h4>
                                                    <p className="text mb-1">{this.state.share.profile.company ? this.state.share.profile.company : ""}</p>
                                                    <p className="text">{this.state.share.profile.city ? this.state.share.profile.city : ""}</p>
                                                   
                                                    <button type="button" class="buttonstyle btn btn-outline-secondary" onClick={e => this._saveVCard(this.state.share.profile)}>Hämta som vCard</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 main1">
                                    <div className="card mb-3 borderstyle">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <h6 className="mb-0 align-left">Namn</h6>
                                                </div>
                                                <div className="col-sm-9 text-secondary align-left">
                                                    {(this.state.share.profile.givenname ? this.state.share.profile.givenname : "") + " " + (this.state.share.profile.surname ? this.state.share.profile.surname : "")}
                                                </div>
                                            </div>
                                            {this.state.share.profile.email ? <>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <h6 className="mb-0 align-left">Epost</h6>
                                                    </div>
                                                    <div className="col-sm-9 text-secondary align-left">
                                                        {this.state.share.profile.email ? <a href={"mailto:" + this.state.share.profile.email}>{this.state.share.profile.email}</a> : ""}
                                                    </div>
                                                </div></> : null}
                                            {this.state.share.profile.phone ? <>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3 align-left">
                                                        <h6 className="mb-0 align-left">Telefon</h6>
                                                    </div>
                                                    <div className="col-sm-9 text-secondary align-left">
                                                        {this.state.share.profile.phone ? <a href={"tel:" + this.state.share.profile.phone}>{this.state.share.profile.phone}</a> : ""}
                                                    </div>
                                                </div></> : null}
                                            {this.state.share.profile.street ?
                                                <>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <h6 className="mb-0 align-left">Adress</h6>
                                                        </div>
                                                        <div className="col-sm-9 text-secondary align-left">
                                                            {(this.state.share.profile.street) + " " + (this.state.share.profile.city ? this.state.share.profile.city : "")}
                                                        </div>
                                                    </div></> : null}
                                            {this._renderProfileData(this.state.share.profile)}
                                        </div>
                                    </div>
                                </div >
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-8">
                                    <div className="card">
                                        <div className="card-body">
                                            <a href={'kforcontact://share/' + this.state.share.guid}>
                                                <QrCode guid={'https://link.kforcontact.com/share/' + this.state.share.guid} />
                                            </a><br /><br />
                                            <p>{this.props.translation.text["SHARE_QR_TEXT"]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                )
            } else {
                return (
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-sm-9 col-lg-4 share-text" >
                                {this.state.share.profile.image_guid !== "" ? <img src={'https://api.kforcontact.com/v1/image/' + this.state.share.profile.image_guid} alt="profile-pic" className="img-responsive person-img" /> : <img src={require('../img/k-profile.png')} className="img-responsive person-img" alt="person" />}
                                <h3>{this.props.translation.text["SHARE_INFO_TITLE"]}! {this.state.share.profile.nickname} {this.props.translation.text["SHARE_INFO_TITLE1"]}</h3>
                                <p>{this.props.translation.text["SHARE_INFO_TEXT"]}</p>
                            </div>
                            <div className="col-md-8 col-sm-8 col-lg-3 col-md-3 code-img text-center" >
                                <a href={'kforcontact://share/' + this.state.share.guid}>
                                    <QrCode guid={'https://link.kforcontact.com/share/' + this.state.share.guid} />
                                </a><br />
                                <p>{this.props.translation.text["SHARE_QR_TEXT"]}</p>
                            </div>
                            <div className="col-md-8 col-sm-8 col-lg-3 code-img text-center" >
                                <img src={require('../img/k-lock.png')} className="img-responsive margin" alt="cloud" width="40%" />
                            </div>
                        </div>
                    </div>
                );
            }
        }
        else if (this.state.loading) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-md-1 col-sm-1 col-lg-1 text-center rounded">

                        </div>
                        <div className="col-md-8 col-sm-8 col-lg-3 share-text" >
                            <h3>Laddar...</h3>
                            <p>Vänligen vänta, vi laddar delningen från servern.</p>
                        </div>
                        <div className="col-md-8 col-sm-8 col-lg-3 col-md-3 code-img text-center" >

                        </div>
                        <div className="col-md-8 col-sm-8 col-lg-3 code-img text-center" >
                            <img src={require('../img/k-lock.png')} className="img-responsive margin" alt="cloud" width="40%" />
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-md-1 col-sm-1 col-lg-1 text-center rounded">

                        </div>
                        <div className="col-md-8 col-sm-12 col-lg-3 share-text" >
                            <h3>Ingen delning</h3>
                            <p>Tyvärr hittades ingen delning.</p>
                        </div>
                        <div className="col-md-4 col-sm-8 col-lg-3 code-img text-center" >
                            <img src={require('../img/k-lock.png')} className="img-responsive margin" alt="cloud" width="40%" />
                        </div>
                    </div>
                </div>
            );
        }
    }

    scrollToMyRef = () => {
        window.scrollBy(
            {
                top: this.myRef.current.getBoundingClientRect().top,
                left: 0,
                behavior: 'smooth'
                // run this method to execute scrolling.
            })
    }

    render() {
        document.title = 'Delning | K for Contact';
        return (
            <div>
                
                
                {/* First Container */}
            
                
                <div class="img-fluid text -center background" id="featuredimage">
                    <div class="main-image text-center" alt="Responaive image"></div>

                </div>
                
                <div id="featuredimage" className="container-fluid text-center background-for-share">
                    {this.renderShare()}
                    <div className="container second-container text-center">
                        <div className="row app-goole-img">
                            <div className="col-lg-6 col-md-6 col-sm-12 ">
                                <a href="https://itunes.apple.com/us/app/k-for-contact/id1363526654?l=sv&ls=1&mt=8">
                                    <img src={require('../img/app-store.png')} alt="" className="first-img" />
                                </a>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 ">
                                <a href="https://play.google.com/store/apps/details?id=com.kforcontact">
                                    <img src={require('../img/google-play.png')} alt="" className="second-img" />
                                </a>
                            </div>
                        </div>
                        <h3>{this.props.translation.text["SHARE_INFO_HEADING"]}</h3>
                        <FontAwesomeIcon className="down" icon={faAngleDown} onClick={e => this.scrollToMyRef()} />
                    </div>
                </div>
                
                {/*Second Container */}
                <div className="container-fluid video-container">
                    <div className="container" ref={this.myRef}>
                        <VideoComponent />
                    </div>
                </div>
                {/*Third Container (Grid)*/}
                <div className="container">
                    <div className="row text-center share-usps">
                        <Categorie categorieSlug="usps" />
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        translation: state.translation
    };
}

export default connect(mapStateToProps)(Share);
import React from 'react';
import '../../node_modules/video-react/dist/video-react.css'; // import css
import { Player, ControlBar } from 'video-react';
import { connect } from 'react-redux';
import './VideoComponent.css';

class VideoComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            player: {}
        }
    }

    componentDidMount() {
        this.refs.player.subscribeToStateChange(this.handleStateChange.bind(this));
    }

    handleStateChange(state, prevState) {
        // copy player state to this component's state
        this.setState({
            player: state
        });
    }

    _rendervideo() {
        if (this.state.player.currentTime < 5) {
            return (
                <div>
                    <h2> {this.props.translation.text["SHARE_VIDEO_PART1_HEADIND"]}</h2>
                    <p>{this.props.translation.text["SHARE_VIDEO_PART1_TEXT"]} </p>
                </div>
            );
        }
        if (this.state.player.currentTime > 5 && this.state.player.currentTime < 10) {
            return (
                <div>
                    <h2> {this.props.translation.text["SHARE_VIDEO_PART2_HEADIND"]}</h2>
                    <p>{this.props.translation.text["SHARE_VIDEO_PART2_TEXT"]} </p>
                </div>
            );
        }
        if (this.state.player.currentTime > 10 && this.state.player.currentTime < 17) {
            return (
                <div>
                    <h2> {this.props.translation.text["SHARE_VIDEO_PART3_HEADIND"]}</h2>
                    <h4>{this.props.translation.text["SHARE_VIDEO_PART3_TEXT"]} </h4>
                </div>
            );
        }
        if (this.state.player.currentTime > 17 && this.state.player.currentTime < 25) {
            return (
                <div>
                    <h2> {this.props.translation.text["SHARE_VIDEO_PART4_HEADIND"]}</h2>
                    <p>{this.props.translation.text["SHARE_VIDEO_PART4_TEXT"]} </p>
                </div>
            );
        }
        if (this.state.player.currentTime > 25) {
            return (
                <div>
                    <h2> {this.props.translation.text["SHARE_VIDEO_PART5_HEADIND"]}</h2>
                    <p>{this.props.translation.text["SHARE_VIDEO_PART5_TEXT"]} </p>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 video">
                    <Player ref="player" autoPlay >
                        <source src={require("../video/190222_K_for_Contact_Intro_30sek.mp4")} />
                        <ControlBar autoHide={false} />
                    </Player>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 video-one share-text-margin">
                    {this._rendervideo()}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        translation: state.translation
    };
}

export default connect(mapStateToProps)(VideoComponent);

import React from 'react';
import { Link } from 'react-router-dom';
import './Menu.css';
import Helper from '../services/Helper';


class Menu extends React.Component {
    constructor() {
        super();
        this.state = {
            menu: [],
            subMenu: []
        }
    }
    componentDidMount() {
        fetch(Helper.fetchUrl() + `/wp-json/k-menu/v1/main-menu`)
            .then(response => response.json()
            ).then(menu => {
                for(let i = 0; i < menu.length; i++) {
                    if(menu[i].menu_item_parent != 0) {
                        this.setState({
                            subMenu: [...this.state.subMenu, menu[i]]
                        })
                    } else {
                        this.setState({
                            menu: [...this.state.menu, menu[i]]
                        })
                        
                    }
                }
            })
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) {
        let elFeaturedImage = document.getElementById("featuredimage");
        let elTopNavigationMenu = document.getElementById("topNavigationMenu");
        if (elFeaturedImage && elTopNavigationMenu) {
            // check to see if both elements exist before trying to calculate
            let featuredImagePos = elFeaturedImage.getBoundingClientRect().bottom;
            let mainNavbarHeight = elTopNavigationMenu.offsetHeight;
            // when the featured image dissapears under the menu switch class
            if ((featuredImagePos - mainNavbarHeight) < 0) {
                document.getElementById("topNavigationMenu").classList.add('nonTransparentMenu');
                document.getElementById("topNavigationMenu").classList.remove('transparentMenu');

            } else {
                document.getElementById("topNavigationMenu").classList.add('transparentMenu');
                document.getElementById("topNavigationMenu").classList.remove('nonTransparentMenu');
            }
        }
    }


    renderSubMenu(parentID) {
        let listItems = this.state.subMenu.map((item, index) =>{
            if(item.menu_item_parent == parentID) {
                return  <li key={"parent_"+index}>
                        <Link to={{
                            pathname: "/"+item.slug,
                            state: { fromDashboard: true }
                        }}>
                        {item.title}
                        </Link>
                        </li>
            }
        })
        return listItems;
    }

    renderMenu() {
        let menuItems = this.state.menu.map((item, index) =>{
            for(let i=0; i < this.state.subMenu.length; i++) {
                if(this.state.subMenu[i].menu_item_parent == item.ID) {
                    return  <li key={"child_"+ item.ID+ "_"+index} className="dropdown">
                                <Link className="dropdown-toggle disabled" to={{
                                    pathname: "/"+item.slug,
                                    state: { fromDashboard: true }
                                }}>
                                {item.title} <span className="caret"></span>
                                </Link>
                                <ul className="dropdown-menu">
                                   {this.renderSubMenu(item.ID)}
                                </ul>
                            </li>
                }
            } return <li key={"child_"+ item.ID+ "_"+index}>
                        <Link to={{
                            pathname: "/"+item.slug,
                            state: { fromDashboard: true }
                        }}>
                        {item.title}
                        </Link>
                     </li>
        })
        return menuItems;
    }

     
    /*renderMenu(parentID, depth) {
        depth++;
          let  listItems = this.state.menu.map((item, index) =>{
            if(item.ID > 0 && item.menu_item_parent == parentID && depth < 2) {
                console.log(depth);
            return  <li key={index} className="dropdown">
                            <a className="dropdown-toggle" href={"/" + item.slug}  data-toggle="dropdown">
                            {item.title} <span class="caret"></span>
                            </a>
                            <ul class="dropdown-menu">
                                {this.renderMenu(item.ID, depth)}
                            </ul>
                        </li>
                     
            } else if(item.ID > 0 && item.menu_item_parent == parentID && depth == 2) {
                return  <li key={index}>
                            <a href={"/" + item.slug} className="dropdown-item">
                                {item.title} {this.renderMenu(item.ID, depth)}
                            </a>
                        </li>
            }
          }
        );
        if(listItems.length > 0) {
            if(depth == 1) {
                return <ul className="nav navbar-inverse"> {listItems} </ul>
            } else if(depth > 1) {
                return <div>{listItems}</div>
            }

        }
        
        return listItems;
    }*/

    render() {
        return (
            <nav className="navbar navbar-navbar fixed-top navbar-expand-lg navbar-light bg-light navbar-inverse nonTransparentMenu" id="topNavigationMenu">
                <div className="container">
                    <div className="navbar-header">
                        <Link className="navbar-brand"  to={{
                            pathname: "/",
                            state: { fromDashboard: true }
                        }}>
                            <img src={require('../img/K_logo02.png')} className="d-inline-block align-top" alt="" />
                        </Link>
                    </div>
                    <button className="navbar-toggler collapsed" type="button" data-toggle="collapse"
                                data-target="#navbarNavDropdown1" aria-expanded="false">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-collapse collapse" id="navbarNavDropdown1" >
                    <ul className="nav navbar-nav navbar-right">
                        {this.renderMenu()}
                    </ul>
                    </div>
                </div>
            </nav>
        )
    }
}
export default Menu;
import React from 'react';
import './Download.css';
import VideoComponent from './VideoComponent';

class LaddaNed extends React.Component {

    render() {
        return (
            <div className="ladda-background" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 ladda-text">
                            <h2>{this.props.page.title.rendered}</h2>
                            <div dangerouslySetInnerHTML={{ __html: this.props.page.content.rendered }} />
                            <div className="row app-goole-img">
                                <div className="col-lg-6 col-md-6 col-sm-12 app-img" >
                                    <a href="https://itunes.apple.com/us/app/k-for-contact/id1363526654?l=sv&ls=1&mt=8">
                                        <img src={require('../img/app-store.png')} alt="" className="first-img" />
                                    </a>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <a href="https://play.google.com/store/apps/details?id=com.kforcontact">
                                        <img src={require('../img/google-play.png')} alt="" className="second-img" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 ladda-img" id="featuredimage">
                            <img src={require('../img/iosorandroid.png')} alt="" />
                        </div>
                    </div>
                </div>
                <div className="container-fluidd download-video">
                    <div className="container" ref={this.myRef}>
                        <VideoComponent />
                    </div>
                </div>
            </div>
        )
    }
}

export default LaddaNed;
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './App.css';
import Page from './components/Page';
import Menu from './components/Menu';
import Home from './components/Home';
import Share from './components/Share';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { addTranslation } from './actions';
import Footer from './components/Footer';
import Helper from './services/Helper';
import Post from './components/Post';
import ScrollUp from './components/ScrollUp';

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            translationLoaded: false
        }
    }
    componentDidMount() {
        fetch(Helper.fetchUrl() + '/wp-json/k-translation/v1/translation')
            .then(response => response.json()
            ).then(response => {
                this.props.addTranslation(response)
                this.setState({ translationLoaded: true })
            }).catch((err) => {
                this.setState({ translationLoaded: true })
            })
    }


    render() {
        if (this.state.translationLoaded) {
            return (
                <div className="App font">
                    <Router>
                        <ScrollUp>
                            <Menu history={this.props.history}/>
                            <Route path='/' exact component={Home} />
                            <Route path='/share/:id' component={Share} />
                            <Route path='/:slug' exact component={Page} />
                            <Route path='/post/:slug' component={Post} />
                            <Footer />
                        </ScrollUp>
                    </Router>
                </div>
            );
        } else {
            return null
        }
    }
}
function mapStateToProps(state) {
    return {
        translation: state.translation
    };
}
const mapDispatchToProps = dispatch => ({
    addTranslation: text => dispatch(addTranslation(text))
})
export default connect(mapStateToProps, mapDispatchToProps)(App);
import React from 'react';
import Helper from '../services/Helper'

class Address extends React.Component {
    _isMounted = false;

    constructor() {
        super();
        this.state = {
            address: { address_line: "", address_postcode: "", address_city: "", address_contry: "" },
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        fetch(Helper.fetchUrl() + `/wp-json/k-address/v1/address`)
            .then(response => response.json()
            ).then(address => {
                if (this._isMounted) {         
                    this.setState(() => ({ address }))
                }
            })
    }
    render() {
        return (
            <div>
                <li>{this.state.address.address_line}</li>
                <li>{this.state.address.address_postcode} {this.state.address.address_city}</li>
                <li>{this.state.address.address_contry}</li>
            </div>
        )
    }
}

export default Address;
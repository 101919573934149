import React from 'react';
import Media from '../services/Media';
import Helper from '../services/Helper';
import './Post.css';

class Post extends React.Component {
    _isMounted = false;

    constructor() {
        super();
        this.state = {
            post: {
                title: "",
                content: {},
                media: {},
            },
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;      
        const { slug } = this.props.match.params
        fetch(Helper.fetchUrl() + `/wp-json/wp/v2/posts?slug=${slug}`)
            .then(response => response.json()
            ).then(post => {
                this.handlePost(post[0]);
            })
    }

    componentDidUpdate(prevProps) {
        const { slug } = this.props.match.params
        const oldslug = prevProps.match.params.slug
        if (oldslug !== slug) {
            fetch(Helper.fetchUrl() + `/wp-json/wp/v2/posts?slug=${slug}`)
                .then(response => response.json()
                ).then(post => {
                    this.handlePost(post[0]);
                })
        }
    }

    handlePost(post) {
        var that = this;
        Media.fetchMedia(post.featured_media).then((image) => {
            post.media = image;
            document.title = post.title.rendered + ' | K for Contact';
            if (that._isMounted) {         
                that.setState(() => ({ post }))
            }
        })
    }

    renderImage() {
        if (this.state.post.media.source_url) {
            return (
                <img
                    className="img-fluid post-img"
                    src={this.state.post.media.source_url}
                    alt={"feturedImage"} />
            )
        } else {
            return null;
        }
    }

    render() {
        return (
            <div className="">
                <div id="featuredimage">
                    {this.renderImage()}
                </div>
                <div className="container post-content">
                    <div className="page">
                        <h1>{this.state.post.title.rendered}</h1>
                        <p><em>{new Date(this.state.post.date).toLocaleString()}</em></p>
                        <div dangerouslySetInnerHTML={{ __html: this.state.post.content.rendered }} />
                    </div>
                </div>
            </div>
        )
    }
}
export default Post;
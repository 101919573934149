import React from "react";
import QRCode from "qrcode.react";
import './Qr-code.css';

class QrCode extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            qrCode: [],
        }
    }

    render() {
        return (
            <QRCode
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="Q"
                size={256}
                value={this.props.guid}
            />
        );
    }
}

export default QrCode;

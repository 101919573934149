const translation = (state = { text: {} }, action) => {
    switch (action.type) {
        case 'ADD_TRANSLATION':
            return Object.assign(state,
                {
                    text: action.text,
                })

        default:
            return state
    }
}
export default translation;